import React, { useEffect, useState } from 'react'
import {
  useCompareTrainingOffersQuery,
  useFilterValuesKnowledgeGroupsAreasQuery,
  useSearchDocumentsQuery,
} from 'frontendApiGraphqlTypes'
import { useLocale } from '../../hooks/useLocale'
import {
  BookmarkDialogProps,
  BookmarkEntry,
  BookmarkList,
  CompareableTrainingOffer,
  ComparePage,
  EditCompareDialogProps,
} from '@hrk/huw-module-library'
import { useTranslations } from '../../hooks/useTranslations'
import { navigate } from 'gatsby'
import { useBookmarks } from '../../hooks/useBookmarks'
import { useSiteMetadata } from '../../hooks/useSiteConfig'
import { useQueryParams } from '../../hooks/useQueryParam'
import { LinkBundle, LinkType } from '@hrk/types'

export const BasicCompare: React.FC = () => {
  const { pageLocale } = useLocale()
  const siteMetadata = useSiteMetadata(pageLocale)
  const [queryIds, setQueryIds] = useQueryParams('id')
  const [compareIds, setCompareIds] = useState<string[]>(queryIds)
  const { bookmarkDialog: bookmarkTranslations } = useTranslations()

  const { data } = useCompareTrainingOffersQuery({
    variables: { ids: compareIds?.slice(0, 3) },
    context: { headers: { 'Accept-Language': pageLocale } },
  })
  const { compare: compareTranslations } = useTranslations()

  useEffect(() => {
    setCompareIds(queryIds)
  }, [queryIds])

  // query all the bookmarks and process compare items to check if they are already bookmarked and favorited
  const { bookmarkLists, updateBookmarkList, createBookmarkList } = useBookmarks()

  const findBookmark = (bookmark: { id: string; [key: string]: unknown }) =>
    bookmarkLists
      .flatMap((v) => v.items.map((item) => item))
      .find((bm) => bm.slug === bookmark.pageSlug || bm.slug === '/' + bookmark.pageSlug)

  const { data: knowledgeAreas } = useFilterValuesKnowledgeGroupsAreasQuery()
  const { data: hsps } = useSearchDocumentsQuery({
    variables: {
      universities: true,
      ids: data?.trainingOffers?.documents
        ?.map(({ document }) => document.universityId)
        .filter((a) => !!a)
        .slice(0, 3) as string[],
    },
    context: { headers: { 'Accept-Language': pageLocale } },
  })

  const compareItems = [
    ...((knowledgeAreas &&
      hsps &&
      data?.trainingOffers?.documents?.map(({ document: compareItem }) => {
        let courseWebsite: LinkBundle | string | null | undefined
        try {
          courseWebsite = compareItem.courseWebsite ? JSON.parse(compareItem.courseWebsite) : compareItem.courseWebsite
        } catch (e) {
          courseWebsite = compareItem.courseWebsite
        }
        const universityWebsite = {
          description: compareItem.universityName || '',
          link: compareItem.universityPageSlug || '',
          linkType: LinkType.internal,
          language: 'de' as 'de' | 'en',
          target: 'self' as 'self' | 'blank',
          status: hsps?.universities?.documents.find(({ document }) => document.id === compareItem.universityId)
            ? 'available'
            : 'unavailable',
        } as LinkBundle
        const keys = [
          'areaId',
          'areaName',
          'areaPageSlug',
          'groupId',
          'groupName',
          'groupPageSlug',
          'groupIconImageSrc',
          'groupIconImageAlt',
        ] as const
        const ka: { [key in typeof keys[number]]: string }[] | undefined = knowledgeAreas.queryDocuments.hits?.map(
          (hit) =>
            hit.document.fields.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {}) as {
              [key in typeof keys[number]]: string
            },
        )
        const knowledgeAreaGroupRelations = compareItem.knowledgeGroupAreas?.map((kga) => ({
          areaPageSlug: ka?.find((f) => f.areaId === kga.area.id)?.areaPageSlug,
          groupPageSlug: ka?.find((f) => f.groupId === kga.group.id)?.groupPageSlug,
          groupIconImageSrc: ka?.find((f) => f.groupId === kga.group.id)?.groupIconImageSrc,
          groupIconImageAlt: ka?.find((f) => f.groupId === kga.group.id)?.groupIconImageAlt,
        }))
        return {
          ...compareItem,
          // TODO: Fix as nested field?
          certificates: JSON.parse(compareItem.certificates ?? 'null'),
          duration: JSON.parse(compareItem.duration ?? 'null'),
          eventFormatSessions: JSON.parse(compareItem.eventFormatSessions ?? 'null'),
          workload: JSON.parse(compareItem.workload ?? 'null'),
          courseWebsite,
          universityWebsite,
          isBookmarked: findBookmark(compareItem as any) !== undefined,
          isFavorited: findBookmark(compareItem as any)?.isFavorite,
          knowledgeAreaGroupRelations,
        }
      })) ||
      []),
    ...[null, null, null],
  ].slice(0, 3) as [CompareableTrainingOffer | null, CompareableTrainingOffer | null, CompareableTrainingOffer | null]

  /**
   * Bookmark dialogs are passed to the compare component because it relies on the
   * GraphQL layer in the `useBookmarks` hook
   */
  const bookmarkDialogProps: BookmarkDialogProps = {
    bookmark: {} as BookmarkEntry,
    bookmarkLists,
    onSaveBookmarkList: async (list: BookmarkList): Promise<void> => {
      'id' in list ? updateBookmarkList(list) : createBookmarkList(list)
    },
    onCancel: () => void 0,
    translations: bookmarkTranslations,
    navigate,
  }

  /**
   * Compare dialogs are passed to the compare component because it relies on the query paramters
   */
  const editCompareDialogProps: EditCompareDialogProps = {
    trainingOffers: compareItems,
    onSetCompareIds: setQueryIds,
    navigate: (url) => {
      return navigate(url + window?.location.search)
    },
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <ComparePage
        compareTrainingOffers={compareItems}
        updateBookmarkList={updateBookmarkList}
        onSetCompareIds={setQueryIds}
        translations={{
          ...compareTranslations,
          heading: siteMetadata.config.compareHeadline,
          introduction: siteMetadata.config.compareIntroduction,
        }}
        bookmarkDialogProps={bookmarkDialogProps}
        editCompareDialogProps={editCompareDialogProps}
        navigate={(url) => {
          return navigate(url + window?.location.search)
        }}
      ></ComparePage>
    </div>
  )
}
